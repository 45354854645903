<template>
    <loading-animation v-if="DataLoading"></loading-animation>
    <div v-else class="ml-3 mr-3">
        <div class="fl-te-c mb-3">
            <div>
                <h4>Purchase Details</h4>
            </div>
            <div class="btn-group">
                <btn @click="$router.push('/purchase/entry/' + id + '/history/')" size="sm" icon="fa fa-history"
                     text="History"/>
                <btn @click="$router.push('/purchase/entry/list/')" size="sm" text="Back"/>
                <btn v-if="entry.status==='Created'" @click="submitClicked" size="sm" text="Submit"/>
            </div>
        </div>
        <purchase-entry-details-table :entry="entry"/>
        <purchase-order-details-table :order="order" class="mt-5"/>
        <div class="card bs-4 p-0" v-if="entry && (entry.status==='Created')">
            <h5 class="p-3 bb-1">PA Status</h5>
            <div class="row p-3">
                <div class="col-1 label">Status</div>
                <div class="col-2">
                    <validated-vue-select name="Status" placeholder="Status" :options="statusOption" v-model="status"/>
                </div>
                <div class="col-1 label">Remarks</div>
                <div class="col-2">
                    <validated-input placeholder="Enter your remarks here" v-model="remarks" :disabled="loading"
                                         :rules="{required:true}"/>
                </div>
            </div>
        </div>
        <payment-details-page v-if="payment"/>
    </div>

</template>

<script>
import PurchaseOrderDetailsTable from './TableComponents/PurchaseOrderDetailsTable';
import PurchaseEntryDetailsTable from './TableComponents/PurchaseEntryDetailsTable';
import PaymentDetailsPage from './TableComponents/PaymentDetails';
import urls from '../../../../data/urls';
import axios from 'secure-axios';

export default {
    name       : 'PurchaseEntryDetailsPage',
    components : { PaymentDetailsPage, PurchaseEntryDetailsTable, PurchaseOrderDetailsTable },
    data () {
        return {
            DataLoading  : false,
            loading      : false,
            payment      : '',
            remarks      : '',
            status       : '',
            entry        : {},
            order        : {},
            id           : this.$route.params.id,
            statusOption : [
                { label : 'Approve', value : 'Approved' },
                { label : 'Reject', value : 'Rejected' }
            ]
        };
    },
    mounted () {
        this.loadDetails();
    },
    methods : {
        async loadDetails () {
            const that = this;
            that.DataLoading = true;
            const response = await axios.form(urls.purchaseAdmin.purchaseEntry.details, { entry_id : that.id });
            const json = response.data;
            if (json.error === false) {
                that.entry = json.entry;
                that.order = json.order;
                that.DataLoading = false;
            } else {
                that.$notify('Details not found.. Please try again later', 'Error', {
                    type : 'danger'
                });
                that.DataLoading = false;
            }
            that.DataLoading = false;
        },
        async submitClicked () {
            const that = this;
            that.loading = true;
            const response = await axios.form(urls.purchaseAdmin.purchaseEntry.submit, {
                entry_id : that.entry.entry_id,
                remarks  : that.remarks,
                status   : that.status
            });
            const json = response.data;
            if (json.error === false) {
                that.$notify('Submitted Successfully', 'Success', {
                    type : 'success'
                });
                that.$router.push('/purchase/entry/list/');
                that.loading = false;
            } else {
                that.$notify('Unable to submit the entry.. Please try again later', 'Error', {
                    type : 'danger'
                });
                that.loading = false;
            }
        }
    }
};
</script>

<style scoped>
.label {
    font-weight: 500;
}
</style>
