<template>
    <div class="mt-5">
        <div class="card bs-4 p-0 pb-3">
            <div class="fl-te-c bb-1 p-3">
                <h5>Payment Details</h5>
            </div>
            <div class="row pl-3 pt-3 pr-5">
                <div class="col-1 label">Amount</div>
                <div class="col-2 p-1 b-1 text-muted round-1">{{ data.payment.amount }}</div>
                <div class="col-1"/>

                <div class="col-1 label">Date</div>
                <div class="col-2 p-1 b-1 text-muted round-1">{{ data.payment.date }}</div>
                <div class="col-1"/>

                <div class="col-1 label">Status</div>
                <div class="col-2 p-1 b-1 text-muted round-1">{{ data.payment.payment_status }}</div>
                <div class="col-1"/>
            </div>
            <div class="row pl-3 pt-3 pr-5">
                <div class="col-1 label">Method</div>
                <div class="col-2 p-1 b-1 text-muted round-1">{{ data.payment.payment_mode }}</div>
                <div class="col-1"/>

                <div class="col-1 label">Reference</div>
                <div class="col-2 p-1 b-1 text-muted round-1">{{ data.payment.reference }}</div>
                <div class="col-1"/>

                <div class="col-1 label">Remarks</div>
                <div class="col-2 p-1 b-1 text-muted round-1">{{ data.payment.remarks }}</div>
                <div class="col-1"/>
            </div>
        </div>
        <div class="card bs-4 p-0 pb-3">
            <div class="fl-te-c">
                <h5 class="ml-3 mt-3 mb-0">Attachments</h5>
            </div>
            <custom-simple-table class="table-borderless mt-4 mb-0" v-if="data" ref="table" title="Items"
                                 :fields="fields" :data="data.attachments">
                <template #actions="{rowData}">
                    <div class="btn-group-tight">
                        <btn class="btn-basic-b" size="xs" @click="viewFile(rowData)" icon="fa fa-eye"></btn>
                    </div>
                </template>
            </custom-simple-table>
        </div>
    </div>
</template>

<script>
export default {
    name  : 'PaymentDetailsPage',
    props : { payment : { type : Object } },
    data () {
        return {
            data        : '',
            DataLoading : false,
            fields      : [
                {
                    name      : 'id',
                    sortField : 'id',
                    title     : 'No.'
                },
                {
                    name      : 'file',
                    sortField : 'file',
                    title     : 'Attachment'
                },
                {
                    name      : '__slot:actions',
                    sortField : '',
                    title     : 'Actions'
                }
            ]
        };
    },
    mounted () {
        this.data = this.payment;
    },
    methods : {
        viewFile (item) {
            window.open(item.url);
        }
    }
};
</script>

<style scoped>
.label {
    font-weight: 500;
}
</style>
