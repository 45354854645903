<template>
    <div v-if="data" class="card bs-4 p-0 pb-3">
        <h5 class="p-3 bb-1">Purchase Info</h5>
        <div class="row pl-3 pt-3 pr-5">
            <div class="col-1 label">Purchase ID</div>
            <div class="col-2 p-1 b-1 text-muted round-1">{{ data.entry_id }}</div>
            <div class="col-1"/>

            <div class="col-1 label">Vendor</div>
            <div class="col-2 p-1 b-1 text-muted round-1">{{ data.vendor }}</div>
            <div class="col-1"/>

            <div class="col-1 label">Created Date</div>
            <div class="col-2 p-1 b-1 text-muted round-1">{{ data.date }}</div>
            <div class="col-1"/>
        </div>
        <div class="row pl-3 pt-3 pr-5">
            <div class="col-1 label">Status</div>
            <div class="col-2 p-1 b-1 text-muted round-1">{{ data.status }}</div>
            <div class="col-1"/>

            <div class="col-1 label">Created By</div>
            <div class="col-2 p-1 b-1 text-muted round-1">{{ data.created_by }}</div>
            <div class="col-1"/>

            <div v-if="data.remarks" class="col-1 label">Remarks</div>
            <div v-if="data.remarks" class="col-2 p-1 b-1 text-muted round-1">{{ data.remarks }}</div>
            <div v-if="data.remarks" class="col-1"/>
        </div>
        <custom-simple-table class="table-borderless mt-4 mb-0" v-if="data" ref="table" title="Items" :fields="fields"
                             :data="data.items">
            <template #no="rowData">
                <span>{{ getSlNo(rowData.rowData, data.items) }}</span>
            </template>
        </custom-simple-table>
    </div>

</template>

<script>
export default {
    name  : 'PurchaseEntryDetailsTable',
    props : { entry : { type : Object } },
    data () {
        return {
            data        : '',
            DataLoading : false,
            fields      : [
                {
                    name      : '__slot:no',
                    sortField : 'no',
                    title     : 'Sl No'
                },
                {
                    name      : 'product_name',
                    sortField : 'product_name',
                    title     : 'Items'
                },
                {
                    name      : 'price',
                    sortField : 'price',
                    title     : 'Price'
                },
                {
                    name      : 'quantity',
                    sortField : 'quantity',
                    title     : 'Quantity'
                },
                {
                    name      : 'sub_total',
                    sortField : 'sub_total',
                    title     : 'Sub Total'
                },
                {
                    name      : 'tax',
                    sortField : 'tax',
                    title     : 'Tax'
                },
                {
                    name      : 'total',
                    sortField : 'total',
                    title     : 'Total'
                }
            ]
        };
    },
    mounted () {
        this.data = this.entry;
    },
    methods : {
        getSlNo (rowData, items) {
            for (let i = 0; i <= items.length; i++) {
                if (items[i].id === rowData.id) {
                    return i + 1;
                }
            }
        }
    }
};
</script>

<style scoped>
.label {
    font-weight: 500;
}
</style>
